<script setup>
import { useFiltersStore } from '~/stores/filters.store';
import { useHomeStore } from '~/stores/home.store';
import { ref } from 'vue';

const filtersStore = useFiltersStore();
const homeStore = useHomeStore();
const countries = ref([]);

const fetched_countries = await homeStore.fetchCountriesHasProperties();
countries.value = fetched_countries;
</script>
<template>
	<section>
		<div v-if="countries?.length > 0" class="container mx-auto my-10 max-w-7xl px-4 sm:px-6 lg:px-8">
			<div class="flex lg:flex-row sm:flex-col md:flex-col lg:gap-8 md::gap-4 columns-2">
				<div class="relative w-full sm:mb-2 lg:mb-0" v-for="country in countries" :key="country._id">

					<NuxtLink :to="localePath({ name: 'slug', params: { slug: country.url.split('/') } })">
						<img class="h-40 w-full object-fill rounded-2xl" :src="`${country.background}?w=592&h=160&fit=cover`" :alt="country.name" />
						<div class="absolute inset-0 bg-gray-900 opacity-40 rounded-2xl"></div>
						<div class="absolute inset-0 flex items-center justify-center">
							<div class="text-center">
								<span class="text-white text-2xl font-bold leading-normal">
									{{ country.name }}
								</span>
								<br />
								<span class="text-white font-normal leading-none">{{ $t('client.investment_opportunity') }}</span>
							</div>
						</div>
					</NuxtLink>

				</div>
			</div>
		</div>
	</section>
</template>
